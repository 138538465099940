import { createReducer, on, createFeatureSelector, Action } from '@ngrx/store';
import { EntityState, createEntityAdapter } from "@ngrx/entity";
import { Session } from '../models/session';
import * as sessionActions from '../actions/session.actions';

export interface State {
	loading?: boolean;
	session?: Session | undefined;
}

const init: State = {
	loading: false,
	session: undefined
}

const reducer = createReducer(init,

	on(sessionActions.signin, (state, payload) => ({ ...state = { loading: payload.loading } })),
	on(sessionActions.signin_success, (state, payload) => ({ ...state = payload })),
	on(sessionActions.signin_failure, (state, payload) => ({ ...state = payload })),

	on(sessionActions.load, (state, payload) => ({ ...state = payload })),
	on(sessionActions.load_success, (state, payload) => ({ ...state = payload })),
	on(sessionActions.load_failure, (state, payload) => ({ ...state = payload })),

	on(sessionActions.signout, (state, payload) => ({ ...state = payload })),
	on(sessionActions.signout_success, (state, payload) => ({ ...state = payload })),
	on(sessionActions.signout_failure, (state, payload) => ({ ...state = payload })),

);

export function sessionReducer(state: State, action: Action) {
	return reducer(state, action);
}
