import { createAction, props } from "@ngrx/store";
import { Session } from '../models/session';
import { Credentials } from '../models/login';

export const signin = createAction('[Session] Signin', props<{ loading: boolean, credentials: Credentials }>());
export const signin_success = createAction('[Session] Signin Success', props<{ loading: boolean, session: Session }>());
export const signin_failure = createAction('[Session] Signin Failure', props<{ loading: boolean, session: Session | undefined }>());

export const signout = createAction('[Session] Signout', props<{ loading: boolean }>());
export const signout_success = createAction('[Session] Signout Success', props<{ loading: boolean, session: Session }>());
export const signout_failure = createAction('[Session] Signout Failure', props<{ loading: boolean, session: Session | undefined }>());

export const load = createAction('[Session] Load', props<{ loading: boolean }>());
export const load_success = createAction('[Session] Load Success', props<{ loading: boolean, session: Session }>());
export const load_failure = createAction('[Session] Load Failure', props<{ loading: boolean, session: Session | undefined}>());


