import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
} from "@angular/common/http";
import { Store, select } from "@ngrx/store";
import { Session } from "src/app/models/session";
import { State } from "src/app/reducers/session.reducer";

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
	constructor(private store: Store<{ session: "session" }>) {}

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		let session: Session | undefined = undefined;
		this.store
			.select("session")
			.subscribe((payload: any) => (session = payload.session));

		if (!session?.tsucces) {
			return next.handle(request);
		}

		const cloned = request.clone({
			headers: request.headers.set("token", session.token),
		});

		return next.handle(cloned);
	}
}
