import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Credentials } from "src/app/models/login";
import { Session } from "src/app/models/session";
import { of } from "rxjs";

export interface Certificado {
	tCertificado: any;
	tCodDoc: any;
	tNroDoc: any;
	tPlan: any;
	tOrden?: any;
	recaptcha?: any
}

@Injectable({
	providedIn: "root",
})
export class AuthService {
	constructor(private http: HttpClient) { }

	signin(creds: Credentials) {
		return this.http.get<any>(
			environment.auth.login +
			"?usuario=" +
			creds.usuario +
			"&password=" +
			creds.password
		);
	}

	credenciales(plan: Certificado) {
		// return of({
		// 	'dsTablas': {
		// 		'TT-Credencial': [
		// 			{
		// 				'tapynom': 'Cristian',
		// 				'tfarmacia': '111',
		// 				'tnumero': 111,
		// 				'tpdf': 'https://pdf.com/pdf'
		// 			}
		// 		]
		// 	}
		// })
		return this.http.post(environment.credencial, {
			"tt-datos": [
				{
					"tCertificado": plan.tCertificado,
					"tCodDoc": plan.tCodDoc,
					"tNroDoc": plan.tNroDoc,
					"tPlan": plan.tPlan,
					"recaptcha": true
				}
			]
		});
	}

	login(plan: Certificado) {
		return this.http.post(environment.login, {
			"tt-datos": [
				{
					"tCertificado": plan.tCertificado,
					"tCodDoc": plan.tCodDoc,
					"tNroDoc": plan.tNroDoc,
					"tPlan": plan.tPlan,
					"recaptcha": true
				}
			]
		})

		// return of({
		// 	"dsTablas": {
		// 		"tt-datos": [
		// 			{
		// 				"tPlan": "gfrs",
		// 				"tCertificado": 10791,
		// 				"tCodDoc": 1,
		// 				"tNroDoc": 23233428,
		// 				"tOrden": 0
		// 			}
		// 		],
		// 		"tt-coberturas": [
		// 			{
		// 				"tcobertura": "01\/03\/23",
		// 				"tpdf": "https:\/\/webmail.previncasalud.com.ar:4433\/\/comprobantes\/2023\/3\/FU-GFRS-00010791ntacAilxlinddoQk.pdf",
		// 				"ttimporte": 317000,
		// 				"ttdeuda": 0,
		// 				"ttpuedepagar": false,
		// 				"ttdeudamacro": 0,
		// 				"ttcodcomprob": 1,
		// 				"ttnrocomprob": 9936375
		// 			},
		// 			{
		// 				"tcobertura": "01\/02\/23",
		// 				"tpdf": "https:\/\/webmail.previncasalud.com.ar:4433\/\/comprobantes\/2023\/2\/FU-GFRS-00010791jddaVNbDioBbiaPj.pdf",
		// 				"ttimporte": 317000,
		// 				"ttdeuda": 0,
		// 				"ttpuedepagar": false,
		// 				"ttdeudamacro": 0,
		// 				"ttcodcomprob": 1,
		// 				"ttnrocomprob": 9906404
		// 			},
		// 			{
		// 				"tcobertura": "01\/01\/23",
		// 				"tpdf": "https:\/\/webmail.previncasalud.com.ar:4433\/\/comprobantes\/2023\/1\/FU-GFRS-00010791euoNAdRPlPOQqcbR.pdf",
		// 				"ttimporte": 276000,
		// 				"ttdeuda": 0,
		// 				"ttpuedepagar": false,
		// 				"ttdeudamacro": 0,
		// 				"ttcodcomprob": 1,
		// 				"ttnrocomprob": 9875826
		// 			}
		// 		],
		// 		"tt-response": [
		// 			{
		// 				"tsucces": true,
		// 				"tobs": "Datos correctos!"
		// 			}
		// 		]
		// 	}
		// })
	}
}
