import { ActionReducer, ActionReducerMap, createFeatureSelector, createSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { sessionReducer } from './session.reducer';
import { inquiryReducer } from './inquiry.reducer';
import { sellsReducer } from './sell.reducer';

export interface State {

}

export const reducers: ActionReducerMap<State> = {
	session: sessionReducer,
	inquiries: inquiryReducer,
	sells: sellsReducer
};


export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
