import { NgModule, NO_ERRORS_SCHEMA, enableProdMode } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { Network } from "@ionic-native/network/ngx";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";

import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { IonicStorageModule } from "@ionic/storage";
import { Device } from "@ionic-native/device/ngx";
import { HttpConfigInterceptor } from "./services/interceptor/interceptor";
import { StoreModule } from "@ngrx/store";
import { reducers } from "./reducers";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../environments/environment";
import { EffectsModule } from "@ngrx/effects";
import { SessionEffect } from "./effects/session.effect";
import { InquiryEffect } from "./effects/inquiry.effect";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { ServiceWorkerModule } from "@angular/service-worker";
import { FormsModule } from "@angular/forms";
import { SellEffect } from "./effects/sell.effect";
import { UploadComponent } from "./components/upload/upload.component";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HintComponent } from "./components/hint/hint.component";

@NgModule({
    declarations: [AppComponent, UploadComponent, HintComponent],
    imports: [
        FormsModule,
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot({
            name: "__as-database",
            driverOrder: ["indexeddb", "sqlite", "websql"],
        }),
        AppRoutingModule,
        StoreModule.forRoot(reducers),
        EffectsModule.forRoot([SessionEffect, InquiryEffect, SellEffect]),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
        }),
        ServiceWorkerModule.register("ngsw-worker.js", {
            enabled: environment.production,
        }),
        ToastrModule.forRoot(),
    ],
    providers: [
        StatusBar,
        SplashScreen,
        Device,
        Network,
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpConfigInterceptor,
            multi: true,
        },
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy,
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
enableProdMode();