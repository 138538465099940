import { createReducer, on, createFeatureSelector, Action } from '@ngrx/store';
import { EntityState, createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { Sell } from '../models/sell';
import * as sellActions from '../actions/sell.actions';

const initial = {
	loading: false,
	ids: [],
	entities: {}
}

export interface State extends EntityState<Sell> {
	loading: boolean
}

export const adapter: EntityAdapter<Sell> = createEntityAdapter<Sell>({
	selectId: (sell: Sell) => sell.ttnrosusc
});

export const initialState: State = adapter.getInitialState(initial);

const reducer = createReducer(initialState,
	on(sellActions.load, (state, { loading }) => ({ ...state, loading })),
	on(sellActions.load_success, (state, { sells }) => (adapter.setAll(sells, state))),
	on(sellActions.load_success, (state, { loading }) => ({ ...state, loading: loading })),
	on(sellActions.load_fail, (state, { loading }) => ({ ...state, loading })),
);

export function sellsReducer(state: State, action: Action) {
	return reducer(state, action);
}

export const getSalesState = createFeatureSelector<State>('sells');

export const { selectAll } = adapter.getSelectors(getSalesState);
