import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Sell, UploadResult } from 'src/app/models/sell';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class SellService {

	constructor(
		private http: HttpClient) { }

	get() {
		return this.http.get<Sell[]>(environment.sells);
	}

	upload(form: FormData) {
		return this.http.post(environment.upload, form);
	}

}
