import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import * as sellsActions from '../actions/sell.actions';
import { AuthService } from '../services/auth/auth.service';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SellService } from '../services/sells/sell.service';
import { NotificationService } from '../services/notifications/notification.service';
import { ModalController } from '@ionic/angular';


@Injectable()
export class SellEffect {

	loadSales$ = createEffect(() => this.actions$.pipe(
		ofType(sellsActions.load),
		mergeMap(() => this.sell.get()
			.pipe(
				map((sells) => {
					return ({ type: '[Sell] Load Success', sells: sells['ttventas'], loading: false });
				}),
				catchError(() => {
					this.store.dispatch({ type: '[Sell] Load Fail', loading: false });
					return EMPTY;
				})
			)
		)
	));

	upload$ = createEffect(() => this.actions$.pipe(
		ofType(sellsActions.upload_file),
		mergeMap(({ form }) => this.sell.upload(form)
			.pipe(
				map(() => {
					this.modal.dismiss();
					return ({ type: '[Sell] Load', loading: true });
				}),
				catchError(() => {
					this.notifications.presentToast('Error al subir imagen, intente nuevamente.');
					return EMPTY;
				})
			)
		)
	));

	constructor(
		private store: Store<{ sells: 'sells' }>,
		private router: Router,
		private storage: Storage,
		private modal: ModalController,
		private actions$: Actions,
		private notifications: NotificationService,
		private auth: AuthService,
		private sell: SellService) { }
}