// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
	production: false,
	login: 'https://webmail.previncasalud.com.ar:4433//cgi-bin/wspdprex/WService=previncap/prueba01macro.html',
	credencial: 'https://webmail.previncasalud.com.ar:4433//cgi-bin/wspdprex/WService=previncap/credenciales.html',
	cred: 'https://webmail.previncasalud.com.ar:4433/cgi-bin/wspdprex/WService=previncap/imprimeFactura.html',
	auth: {
		login:
			"https://webmail.previncasalud.com.ar:4433/cgi-bin/wspdprex/WService=previncap/login.html",
		inquiry:
			"http://agencias.americaservicios.com.ar/cgi-bin/cgiip.exe/WService=aswebD/consultaDNI.html",
	},
	upload:
		"http://agencias.americaservicios.com.ar/cgi-bin/cgiip.exe/WService=aswebD/subeAdjuntoVenta.html",
	sells:
		"http://agencias.americaservicios.com.ar/cgi-bin/cgiip.exe/WService=aswebD/ventas.html",
	new: "http://agencias.americaservicios.com.ar/cgi-bin/cgiip.exe/WService=aswebD/Htmventas.htm?ppr=",
	url_ventas:
		"http://agencias.americaservicios.com.ar/cgi-bin/cgiip.exe/WService=aswebD/HtmVentas.htm?prw=",
	links: {
		productores: "/productores-dev",
	},
	validador:
		"https://webmail.previncasalud.com.ar:4433/cgi-bin/wspdprex/WService=previncap/Coberturas.html",
	secretKey: 'PREVINCASRL_ec5567cc-4679-40fc-912a-3394b4306cb1',
	callbackSucces: 'http://localhost:4200/success',
	callbackCancel: 'https://mifacturadesa.previncasalud.com.ar/login/v1/#/main/cobertura',
	sucursalComercio: '2fdd4a42-61a2-463d-8ef6-99486f29bfb1',
	url: 'https://sandboxpp.asjservicios.com.ar',
	overallEncrypterKey: 'test'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
