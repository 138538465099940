import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
	selector: 'app-hint',
	templateUrl: './hint.component.html',
	styleUrls: ['./hint.component.scss'],
})
export class HintComponent {
	@Input() data: string;

	constructor(private popoverCtrl: PopoverController) { }

	closePopover() {
		this.popoverCtrl.dismiss();
	}

}
