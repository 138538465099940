import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private storage: Storage,
    private router: Router) { }

  async canActivate(next: ActivatedRouteSnapshot): Promise<boolean> {
    let session = localStorage.getItem('session');
    console.log(session)
    if (!session) {
      this.router.navigateByUrl('login');
      return false;
    }
    return true;
  }

}
