import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import * as inquiryActions from '../actions/inquiry.actions';
import { AuthService } from '../services/auth/auth.service';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { InquiryService } from '../services/inquiry/inquiry.service';


@Injectable()
export class InquiryEffect {

	read$ = createEffect(() => this.actions$.pipe(
		ofType(inquiryActions.read),
		mergeMap((data) => this.inquiry.read(data.gid)
			.pipe(
				map((inquiry) => {
					return inquiryActions.read_success({ loading: false, inquiry: inquiry['ttconsulta'][0] });
				}),
				catchError(() => {
					return EMPTY;
				})
			)
		)
	));

	constructor(
		private store: Store<{ inquiry: 'inquiry' }>,
		private router: Router,
		private storage: Storage,
		private actions$: Actions,
		private auth: AuthService,
		private inquiry: InquiryService) { }
}