import { createReducer, on, createFeatureSelector, Action } from '@ngrx/store';
import { EntityState, createEntityAdapter } from "@ngrx/entity";
import { Inquiry } from '../models/inquiry';
import * as inquiryActions from '../actions/inquiry.actions';

export interface State {
	loading: boolean;
	inquiry: Inquiry
}

export const init = {
	loading: false,
	inquiry: {}
}

const reducer = createReducer(init,

	on(inquiryActions.read, (state) => ({ ...state, loading: true })),
	on(inquiryActions.read_success, (state, data) => ({ ...state = data })),
	
);

export function inquiryReducer(state: State, action: Action) {
	return reducer(state, action);
}