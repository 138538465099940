import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Inquiry } from 'src/app/models/inquiry';

@Injectable({
	providedIn: 'root'
})
export class InquiryService {

	constructor(
		private http: HttpClient) { }

	read(gid: number) {
		return this.http.get<Inquiry>(environment.auth.inquiry + '?ttnro=' + gid);
	}
}
