import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EMPTY } from "rxjs";
import { map, mergeMap, catchError, switchMap, tap } from "rxjs/operators";
import * as sessionActions from "../actions/session.actions";
import { AuthService } from "../services/auth/auth.service";
import { Session } from "../models/session";
import { Storage } from "@ionic/storage";
import { Router } from "@angular/router";
import { from } from "rxjs";
import { Store } from "@ngrx/store";
import { NotificationService } from "../services/notifications/notification.service";
import { LoadingController } from "@ionic/angular";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class SessionEffect {
	signin$ = createEffect(() =>
		this.actions$.pipe(
			ofType(sessionActions.signin),
			switchMap(({ credentials }) =>
				this.auth.signin(credentials).pipe(
					map((session) => {
						this.storage.set("session", session["tt-response"][0]);
						return sessionActions.signin_success({
							loading: false,
							session: session["tt-response"][0],
						});
					}),
					tap(() => this.router.navigateByUrl("/main/home")),
					catchError((callback) => {
						this.toastr.error(callback.error["tt-response"][0]["tobs"]);
						this.store.dispatch(
							sessionActions.signin_failure({
								loading: false,
								session: undefined,
							})
						);

						return EMPTY;
					})
				)
			)
		)
	);

	signout$ = createEffect(() =>
		this.actions$.pipe(
			ofType(sessionActions.signout),
			mergeMap(() =>
				from(this.storage.remove("session")).pipe(
					map(() => {
						this.notifications.loading.dismiss();
						this.router.navigateByUrl("login");
						return sessionActions.signout_success({
							loading: false,
							session: undefined,
						});
					}),
					catchError(() => {
						this.store.dispatch(
							sessionActions.signout_failure({
								loading: false,
								session: undefined,
							})
						);
						return EMPTY;
					})
				)
			)
		)
	);

	load_session$ = createEffect(() =>
		this.actions$.pipe(
			ofType(sessionActions.load),
			mergeMap(() =>
				from(this.storage.get("session")).pipe(
					map((session: Session) => {
						if (!session) {
							this.store.dispatch(
								sessionActions.load_failure({
									loading: false,
									session: undefined,
								})
							);
						}
						return sessionActions.load_success({
							loading: false,
							session: session,
						});
					}),
					catchError((err) => {
						this.store.dispatch(
							sessionActions.load_failure({
								loading: false,
								session: undefined,
							})
						);
						return EMPTY;
					})
				)
			)
		)
	);

	constructor(
		private notifications: NotificationService,
		private store: Store<{ session: "session" }>,
		private router: Router,
		private storage: Storage,
		private actions$: Actions,
		private loading: LoadingController,
		private auth: AuthService,
		private toastr: ToastrService
	) {}
}
