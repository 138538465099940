// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
	production: false,
	login: 'https://api.previncasalud.com.ar:4430/cgi-bin/wspd_cgi.sh/WService=wssalud/prueba01macro.html',
	credencial: 'https://api.previncasalud.com.ar:4430/cgi-bin/wspd_cgi.sh/WService=wssalud/credenciales.html',
	cred: 'https://api.previncasalud.com.ar:4430/cgi-bin/wspd_cgi.sh/WService=wssalud/imprimeFactura.html',
	auth: {
		login:
			"https://api.previncasalud.com.ar:4430/cgi-bin/wspdprex/WService=wssalud/login.html",
		inquiry:
			"http://agencias.americaservicios.com.ar/cgi-bin/cgiip.exe/WService=aswebD/consultaDNI.html",
	},
	upload:
		"http://agencias.americaservicios.com.ar/cgi-bin/cgiip.exe/WService=aswebD/subeAdjuntoVenta.html",
	sells:
		"http://agencias.americaservicios.com.ar/cgi-bin/cgiip.exe/WService=aswebD/ventas.html",
	new: "http://agencias.americaservicios.com.ar/cgi-bin/cgiip.exe/WService=aswebD/Htmventas.htm?ppr=",
	url_ventas:
		"http://agencias.americaservicios.com.ar/cgi-bin/cgiip.exe/WService=aswebD/HtmVentas.htm?prw=",
	links: {
		productores: "/productores-dev",
	},
	validador:
		"https://api.previncasalud.com.ar:4430/cgi-bin/wspd_cgi.sh/WService=wssalud/oberturas.html",
	secretKey: 'PREVINCASALUD_93dc0200-aa4f-4573-bceb-123f6061c0a4', //'PREVINCASRL_ec5567cc-4679-40fc-912a-3394b4306cb1',
	callbackSucces: 'http://localhost:4200/success',
	callbackCancel: 'https://mifacturadesa.previncasalud.com.ar/login/v1/#/main/cobertura',
	sucursalComercio: 'a73ec8d2-783e-4060-99cc-15e5b8340d1c', //'2fdd4a42-61a2-463d-8ef6-99486f29bfb1',
	url: 'https://botonpp.macroclickpago.com.ar/', //'https://sandboxpp.asjservicios.com.ar',
	overallEncrypterKey: 'test'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
