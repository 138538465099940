import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Store } from '@ngrx/store';
import * as sessionActions from './actions/session.actions';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
	constructor(
		private store: Store<{ session: 'session' }>,
		private platform: Platform,
		private splashScreen: SplashScreen,
		private statusBar: StatusBar) {
		this.initializeApp();
	}

	initializeApp() {
		this.platform.ready().then(() => {
			this.store.dispatch(sessionActions.load({ loading: true }));
			this.statusBar.backgroundColorByHexString('#fff');
			this.statusBar.styleDefault();
			this.splashScreen.hide();
		});
	}

	ngOnInit() { }
}
