import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';

@Injectable({
	providedIn: 'root'
})
export class NotificationService {

	constructor(
		private toast: ToastController,
		public loading: LoadingController) { }

	async presentLoading(message: string) {
		const loading = await this.loading.create({
			message: message,
			spinner: 'crescent',
		});
		await loading.present();
	}

	async presentToast(message: string) {
		let toast = await this.toast.create({
			message: message,
			duration: 2000,
			color: 'light',
			position: 'top',
		});
		await toast.present();
	}

}
