import { Component, Input } from '@angular/core';
import { SellService } from '../../services/sells/sell.service';
import { Store } from '@ngrx/store';
import { ModalController } from '@ionic/angular';
@Component({
	selector: 'app-upload',
	templateUrl: './upload.component.html',
	styleUrls: ['./upload.component.scss'],
})
export class UploadComponent {

	@Input() private slot: any;
	@Input() private suscription: string;
	public file: Blob;

	constructor(
		private modal: ModalController,
		private store: Store,
		private sell: SellService) { }

	change(event: any) {
		this.file = event.target.files[0];
	}

	upload() {
		let formData = new FormData();
		formData.append('file', this.file);
		formData.append('ttnro', this.slot);
		formData.append('ttsusc', this.suscription);
		this.store.dispatch({ type: '[Sell] Upload File', form: formData });
	}

	dismissModal() {
		return this.modal.dismiss();
	}

}
